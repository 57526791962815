<template>
	<div style="height: 100%;">
		<main-table v-if="show_table">
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-date-picker v-model="params.month" type="month" value-format="yyyy-MM" placeholder="选择月份"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employee_id" placeholder="请选择员工" filterable multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name+(employee.separated_at ? '(已离职)' : '')" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getSalaries({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="$refs['create'].open({})">添加明细</el-button>
					<!-- <el-button type="primary" @click="printExcel('printArea')">打印</el-button> -->
				</el-form-item>
			</el-form>
			<el-table slot="table" id="printArea" class="scroll-wrapper absolute salaries" height="100%" :data="salaries" :size="theme.size" :row-class-name="tableRowClassName">
				<el-table-column align="center" label="月份" min-width="80">
					<template slot-scope="scope">{{scope.row.created_at | format_at}}</template>
				</el-table-column>
				<el-table-column align="center" label="姓名" prop="employee.name" min-width="90"></el-table-column>
				<template v-if="fields.filter(({type}) => type == 0).length">
					<el-table-column min-width="60" v-for="column in fields.filter(({type}) => type == 0)" :key="column.id" :label="column.name">
						<el-link slot-scope="scope" type="primary" @click="onField(scope.row.id, column.id)">{{+scope.row['column_'+column.id]}}</el-link>
					</el-table-column>
				</template>
				<el-table-column label="应发" align="center" v-if="fields.filter(({type}) => type == 1).length">
					<el-table-column align="center" min-width="80" v-for="column in fields.filter(({type}) => type == 1)" :key="column.id" :label="column.name">
						<el-link slot-scope="scope" type="primary" @click="onField(scope.row.id, column.id)">{{+scope.row['column_'+column.id]}}</el-link>
					</el-table-column>
					<el-table-column align="center" min-width="80" label="其它">
						<el-link slot-scope="scope" type="primary" @click="onOther(scope.row.id, 1)">{{+scope.row.column_other_1}}</el-link>
					</el-table-column>
					<el-table-column align="center" min-width="80" label="合计" prop="column_type_1" :formatter="({column_type_1}) => { return +column_type_1; }"></el-table-column>
				</el-table-column>
				<el-table-column label="应扣" align="center" v-if="fields.filter(({type}) => type == 2).length">
					<el-table-column align="center" min-width="80" v-for="column in fields.filter(({type}) => type == 2)" :key="column.id" :label="column.name">
						<el-link slot-scope="scope" type="primary" @click="onField(scope.row.id, column.id)">{{+scope.row['column_'+column.id]}}</el-link>
					</el-table-column>
					<el-table-column align="center" min-width="80" label="其它">
						<el-link slot-scope="scope" type="primary" @click="onOther(scope.row.id, 2)">{{+scope.row.column_other_2}}</el-link>
					</el-table-column>
					<el-table-column align="center" min-width="80" label="合计" prop="column_type_2" :formatter="({column_type_2}) => { return +column_type_2; }"></el-table-column>
				</el-table-column>
				<el-table-column align="center" label="实发" min-width="100" :formatter="({actual, column_type_1, column_type_2}) => { return (+actual || +(column_type_1 - column_type_2).toFixed(2)) + ' 元'; }"></el-table-column>
				<el-table-column align="center" label="签名">
					<template slot-scope="scope" v-if="!$moment().isSameOrBefore(scope.row.created_at, 'month')">
						<img style="display: block;" :src="scope.row.signatured_card" v-if="scope.row.signatured_card">
						<span v-else-if="!scope.row.employee || scope.row.employee.separated_at">已离职</span>
						<el-link type="primary" :disabled="!$utils.update($api.URI_SALARIES)" @click="onSignature(scope.row)" v-else>点击签名</el-link>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getSalaries({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getSalaries({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<main-table v-else tool>
			<el-result slot="table" icon="warning" title="警告提示" subTitle="暂不支持查看薪酬列表，请在点击下方按钮初始化薪酬配置默认字段"></el-result>
		</main-table>
		<el-dialog title="确认签名" width="640px" :visible.sync="salaries_dialog" append-to-body destroy-on-close>
			<div style="color: #f1f1f1; font-size: 100px; font-weight: bold; line-height: 300px; text-align: center; background-color: #fdfdfd;">签 名 区 域</div>
			<vue-esign ref="esign" style="margin-top: -300px; border: 1px dashed #999; border-radius: 10px; cursor: pointer;" :isCrop="false" :lineWidth="8" :width="600" :height="300"></vue-esign>
			<template slot="footer">
				<el-button type="danger" :size="theme.size" @click="$refs['esign'].reset()">清空签名</el-button>
				<el-button type="primary" :size="theme.size" @click="handleGenerate">提交签名</el-button>
			</template>
		</el-dialog>
		<create ref="create" :fields="fields" :employees="employees" @refresh="getSalaries(params)"></create>
		<show ref="detail" :fields="fields" @refresh="getSalaries(params)"></show>
	</div>
</template>

<style>
	.el-table.salaries .warning-row {
		background: oldlace;
	}

	.el-table.salaries .success-row {
		background: #f0f9eb;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import vueEsign from 'vue-esign';
	import html2canvas from 'html2canvas'
	import mainTable from '@/components/main-table';
	import create from './create';
	import show from './show';

	export default {
		components: {
			mainTable,
			vueEsign,
			create,
			show
		},
		computed: {
			...mapState(['theme', 'oa_users', 'oa_departments']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM');
			}
		},
		methods: {
			tableRowClassName({row}) {
				if (!row.employee) return 'warning-row';
				if (row.employee.separated_at) return 'warning-row';
				if (row.signatured_card) return 'success-row';
				return '';
			},
			printExcel (id) {
				html2canvas(document.getElementById(id), {
					dpi: 300, // 解决生产图片模糊
					useCORS: true,
					allowTaint: false,
					logging: false,
					scale: 2,
					// windowHeight: this.$refs.businessCard.$el.offsetHeight + 40
				}).then((canvas) => {
					console.log(canvas.toDataURL('image/png'));
				})
				// let printStr = document.getElementById(id).innerHTML;
				// // 打开新页面
				// let pwin = window.open("_blank");
				// // 将内容赋值到新页面
				// pwin.document.write(printStr);
				// // document.getElementsByTagName('body')[0].style.zoom = 0.75
				// pwin.document.close();
				// // 聚焦-不加focuse，在某些情况下，打印页面会有问题。
				// pwin.focus();
				// // 使用setTimeout，等页面dom元素渲染完成后再打印。
				// setTimeout(() => {
				// 	pwin.print(); // 打印功能。 例如 window.print() 直接打印当前整个页面。
				// 	// document.getElementsByTagName('body')[0].style.zoom = 1;
				// 	pwin.close(); // 关闭 打印创建的当前页面
				// }, 500);
			},
			onSignature ({id}) {
				this.salary.id = id;
				this.salaries_dialog = true;
			},
			handleGenerate () {
				this.$refs.esign.generate().then(signatured_card => {
					this.$confirm('提交后不能修改，请认真核对信息无误后提交签名。', '确认提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {
						const res = await this.$http.post(this.$api.URI_SALARIES, {...this.salary, signatured_card});
						const { code, msg: message } = res.data;
						if (code != 0) return this.$message.error(message);
						this.$message.success({
							message,
							onClose: () => {
								this.getSalaries({...this.params, page: 1});
								this.salaries_dialog = false;
							}
						});
					});
				}).catch(() => {
					this.$message.error("请在指定区域内签名！");
				});
			},
			onField (salary_id, salary_field_id) {
				this.$refs['detail'].open({salary_id, salary_field_id});
			},
			onOther (salary_id, column_type) {
				this.$refs['detail'].open({salary_id, column_type});
			},
			async getSalaries (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SALARIES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.salaries = result.data;
				this.fields = result.fields;
				this.employees = result.employees;
				this.show_table = (result.fields && result.fields.length);
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				salary: {
					action: 'update',
				},
				fields: [],
				salaries: [],
				employees: [],
				salaries_dialog: false,
				show_table: true,
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getSalaries(this.params, true);
		}
	};
</script>