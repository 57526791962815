<template>
	<el-dialog width="380px" :title="detail.id ? '编辑明细' : '添加明细'" :visible.sync="details_dialog" :fullscreen="theme.width < 720" @closed="details = [];" append-to-body destroy-on-close>
		<el-form label-width="80px" ref="detail_form" :model="detail" :rules="detail_rules" :size="theme.size" status-icon>
			<template v-if="!detail.id">
				<el-form-item label="月份" prop="month">
					<el-date-picker v-model="detail.month" type="month" value-format="yyyy-MM" placeholder="请选择月份"></el-date-picker>
				</el-form-item>
				<el-form-item label="员工" prop="employee_id">
					<el-select v-model="detail.employee_id" placeholder="请选择员工" filterable>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name+(employee.separated_at ? '(已离职)' : '')" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="字段" prop="salary_field_id">
					<el-select v-model="detail.salary_field_id" placeholder="请选择字段" filterable>
						<el-option v-for="field in fields" :key="field.id" :label="field.name" :value="field.id"></el-option>
					</el-select>
				</el-form-item>
			</template>
			<el-form-item label="数值" prop="amount">
				<el-input-number placeholder="数值" v-model="detail.amount" :precision="2" :step="0.01" :min="0.01"></el-input-number>
			</el-form-item>
			<el-form-item label="备注说明" prop="commit">
				<el-input v-model="detail.commit" type="textarea" placeholder="请输入备注说明。非必填" rows="3" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button slot="label" type="text" @click="downloadTemp" v-if="!detail.id">下载模板</el-button>
				<el-button type="primary" @click="submitForm('detail_form')">{{detail.id ? '提交保存'  : '提交创建'}}</el-button>
				<el-upload style="display: inline-block; margin: 0 10px" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importInventories" :action="$api.URI_UPLOADS" :disabled="!$utils.create($api.URI_SALARIES_DETAILS)" v-if="!detail.id">
					<el-button type="primary" :disabled="!$utils.create($api.URI_SALARIES_DETAILS)" plain>导入明细</el-button>
				</el-upload>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import XLSX from "xlsx";
	import moment from "moment";
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';

	export default {
		computed: {
			...mapState(['theme']),
			table_temp () {
				const thead = ['年月份(例2024-01)', '员工姓名', '字段名称', '数值', '备注说明'];
				const filter = ['month', 'employee_name', 'salary_field_name', 'amount', 'commit'];
				return {
					data: [],
					sheet: '明细列表',
					thead,
					filter
				};
			}
		},
		props: {
			fields: {
				type: Array
			},
			employees: {
				type: Array
			},
		},
		methods: {
			open (d) {
				this.details_dialog = true;
				this.detail = {...d}
			},
			async submitDetail (data) {
				const res = await this.$http.post(this.$api.URI_SALARIES_DETAILS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.$emit('refresh');
						this.details_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'detail_form':
							this.submitDetail(this.detail);
						break;
					}
				});
			},
			importInventories ({raw}) {
				this.importing = true;
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				fileReader.onload = async ({target}) => {
					try {
						const { thead, filter } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						let index = 0;
						let notify = this.$notify({
							title: '提示',
							dangerouslyUseHTMLString: true,
							message: '<p>正在准备导入...</p>',
							showClose: false,
							duration: 0
						});
						for (var i in data) {
							const { month, employee_name, salary_field_name, amount  } = data[i];
							index += 1;
							notify.message = '<p>正在导入 ' + index + ' / ' + data.length + '</p>';
							if (!month) {
								data[i].status = "提交月份不能为空";
								continue;
							}
							if (moment(month).format('YYYY-MM') != month) {
								data[i].status = "提交月份格式有误";
								continue;
							}
							if (!employee_name) {
								data[i].status = "提交员工姓名不能为空";
								continue;
							}
							if (!this.employees.find(e => e.name === employee_name)) {
								data[i].status = "提交员工姓名不存在";
								continue;
							}
							if (!salary_field_name) {
								data[i].status = "提交字段名称不能为空";
								continue;
							}
							if (!this.fields.find(e => e.name === salary_field_name)) {
								data[i].status = "提交字段名称不存在";
								continue;
							}
							if (!amount) {
								data[i].status = "提交数值不能为空";
								continue;
							}
							if (amount < 0) {
								data[i].status = "提交数值必须大于等于0";
								continue;
							}
							const res = await this.$http.post(this.$api.URI_SALARIES_DETAILS, {...data[i]});
							const { code, msg } = res.data;
							data[i].status = msg;
							if (code != 0) continue;
						}
						if (index === data.length) {
							notify.message = '<p>导入完成！</p>';
							setTimeout(() => {
								this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "薪酬明细导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
								notify.close();
								this.$emit('refresh');
								this.details_dialog = false;
							}, 3000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			downloadTemp () {
				const template = [
					{
						filter: ['id', 'name', 'separated_at'],
						thead: ['ID', '姓名', '离职时间'],
						sheet: '有效员工',
						data: this.employees
					}, {
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '有效字段',
						data: this.fields
					}
				];
				template.unshift(this.table_temp);
				ad_export_json_to_excel_multi_sheet(template, '薪酬明细导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			}
		},
		data() {
			return {
				detail: {},
				details_dialog: false,
				detail_rules: {
					month: [{ required: true, message: '请选择月份', trigger: 'blur' }],
					employee_id: [{ required: true, message: '请选择员工', trigger: 'blur' }],
					salary_field_id: [{ required: true, message: '请选择字段', trigger: 'blur' }],
					amount: [{ required: true, message: '请输入数值', trigger: 'blur' }]
				}
			}
		}
	};
</script>